* {
    box-sizing: border-box;

    font-family: "Bricolage Grotesque", sans-serif;
    font-weight: 700;
    font-style: normal;
}

html,
body,
#root,
.page_box {
    width: 100%;
    height: 100%;
}

.regular {
    font-family: "Vast Shadow", serif;
    font-weight: 400;
    font-style: normal;
}

.bold {
    font-family: "Vast Shadow", serif;
    font-weight: 400;
    font-style: normal;
}

::-webkit-scrollbar {
    /* 滚动条整体部分 */
    width: 6px;
    margin-right: 0px;
}

::-webkit-scrollbar:horizontal {
    height: 4px;
    margin-bottom: 4px;
}

::-webkit-scrollbar-thumb {
    /* 滑块 */
    width: 2px;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:hover {
    /* 鼠标移入滑块 */
    background: #909090;
}

@keyframes scaleUpDown {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

.header-box {
    position: fixed;
    bottom: 0.3rem;
    left: 50%;
    transform: translateX(-50%);
    width: 66.6%;
    display: flex;
    justify-content: end;
    @media (max-width: 900px) {
        width: 100%;
        justify-content: space-around;
        bottom: 0.38rem;
    }
    .text {
        font-family: "Bricolage Grotesque", sans-serif;
        font-weight: 400;
        font-size: 0.14rem;
        color: #000000;
        line-height: 0.17rem;
        margin-left: 0.36rem;
        opacity: 0.5;
        @media (max-width: 900px) {
            margin-left: 0;
            font-size: 0.22rem;
            line-height: 0.26rem;
        }
    }
}

.icon {
    width: 2.38rem;
    height: 2.38rem;
    position: absolute;
    object-fit: cover;
}
.icon1 {
    top: 1.15rem;
    left: 0;
    transform: translateX(-37%);
}
.icon2 {
    left: 0.67rem;
    bottom: 0;
    transform: translateY(37%);
    @media (max-width: 900px) {
        left: 0;
        bottom: 30%;
        transform: translate(-50%, 0);
    }
}
.icon3 {
    right: 0;
    bottom: 1.03rem;
    transform: translateX(49%);
    @media (max-width: 900px) {
        display: none;
    }
}
.icon4 {
    top: 0.26rem;
    right: 2.31rem;
    @media (max-width: 900px) {
        display: none;
    }
}

.box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 66.6%;
    height: 75%;
    border-radius: 0.72rem;
    background-color: #fff;
    border: 0.02rem solid #000000;
    display: flex;
    flex-direction: column;
    @media (max-width: 900px) {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
        transform: none;
    }
    .top {
        width: 100%;
        height: 1.44rem;
        border-bottom: 0.02rem solid #000000;
        border-radius: 0.72rem 0.72rem 0 0;
        background: #fff88f;
        padding: 0 1.38rem 0 0.38rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 900px) {
            padding: 0;
            padding-left: 0.44rem;
        }
        .logo_box {
            display: flex;
            align-items: center;
            .logo {
                width: 0.72rem;
                height: 0.72rem;
                margin-right: 0.24rem;
            }
            .text {
                .name {
                    font-family: "Bricolage Grotesque", sans-serif;
                    font-weight: bold;
                    font-size: 0.4rem;
                    color: #000000;
                    line-height: 0.48rem;
                }
                .tip {
                    font-family: "Bricolage Grotesque", sans-serif;
                    font-weight: 400;
                    font-size: 0.2rem;
                    color: #000000;
                    line-height: 0.24rem;
                    text-align: left;
                    font-style: normal;
                }
            }
        }
        .books {
            width: 1rem;
            height: 0.82rem;
            @media (max-width: 900px) {
                display: none;
            }
        }
    }
    
}

.page {
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #affee2 0%, #ffffff 100%);
    position: relative;
    display: flex;
    align-items: end;
    justify-content: center;
    overflow: hidden;

    @media (max-width: 900px) {
        padding: 0.8rem 0.56rem 0;
        align-items: start;
    }
   
    .box {
       
        .bottom {
            flex: 1;
            display: flex;
            justify-content: space-evenly;
            padding: 0 0.64rem;
            align-items: center;
            position: relative;
            @media (max-width: 900px) {
                flex-wrap: wrap;
                padding: 0;
            }
            .img {
                width: 23%;
                height: 3.48rem;
                @media (max-width: 900px) {
                    width: 2.48rem;
                    height: 3.48rem;
                }
            }
            .app_store {
                position: absolute;
                width: 4rem;
                height: 1.2rem;
                bottom: 0.68rem;
                right: 0.48rem;
                z-index: 99;
                img {
                    width: 100%;
                }
                @media (max-width: 900px) {
                    right: 50%;
                    bottom: 0;
                    transform: translate(50%, 50%);
                }
            }
        }
    }
}

.pact-page {
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #affee2 0%, #ffffff 100%);
    position: relative;
    display: flex;
    align-items: end;
    justify-content: center;
    overflow: hidden;

    @media (max-width: 900px) {
        padding: 0.8rem 0.56rem 0;
        align-items: start;
    }
    .box{
        overflow: hidden;
        @media (max-width: 900px) {
           height: 90%;
        }
    }
  .pact-cont{
    flex: 1;
    overflow: scroll;
    scrollbar-width: none; /* Firefox */
    padding: .4rem .84rem;
    &::-webkit-scrollbar {
        display: none;
    }
    @media (max-width: 900px) {
        padding: .4rem ;
     }
    h2 {
               
        font-family: "Bricolage Grotesque", sans-serif;
        font-weight: bold;
        font-size: 0.24rem;
        color: #262626;
        line-height: 0.28rem;
        margin-bottom: 0.3rem;
        font-weight: bold;
        @media (max-width: 900px) {
            font-size: 0.30rem;
            line-height: 0.36rem;
           
        }
    }
    p,
    li {
        font-family: "Bricolage Grotesque", sans-serif;
        font-weight: 300;
        font-size: 0.18rem;
        color: #000;
        line-height: 0.28rem;
        padding-bottom: 0.2rem;
        @media (max-width: 900px) {
            font-size: 0.26rem;
            line-height: 0.40rem;
        }
    }
    strong {
        font-weight: bold;
    }
  }
}
